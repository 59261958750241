import {createSlice} from '@reduxjs/toolkit';
import {subDays, startOfDay} from 'date-fns';
import {dateToString, getDayConstFromEntitlement, getDayConstFromFilters} from '../utils';
import {callTypes} from "../constants";

const userFilters = JSON.parse(localStorage.getItem('userFilters'));

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        loading: true,
        filters: {
            portfolioId: userFilters && userFilters.portfolioId ? parseInt(userFilters.portfolioId) : 745,
            companyName: '',
            startDate: userFilters && userFilters.startDate ? userFilters
                .startDate : dateToString(startOfDay(subDays(new Date(), getDayConstFromFilters(userFilters)))),
            endDate: userFilters && userFilters.endDate ? userFilters.endDate : dateToString(startOfDay(new Date())),
            mode: userFilters && userFilters.mode ? userFilters.mode : 0,
            checkedEntitlement: userFilters && userFilters.selectedEnt && typeof (userFilters.selectedEnt) != 'string' ? userFilters.selectedEnt : callTypes
        },
        topNData: {},
        filterTags: [],
        filterType: 'event', // possible values are event, word, date(on comp page)
        userPortfolios: [],
        userId: '',
        userEntitlements: '',
        userName: '',
        isCollapsed: true,
        companyDateFilter: '',
        keywordList: []
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setStartDate: (state, action) => {
            state.filters.startDate = action.payload
        },
        setEndDate: (state, action) => {
            state.filters.endDate = action.payload
        },
        setPortfolioId: (state, action) => {
            state.filters.portfolioId = action.payload
            localStorage.setItem('userFilters', JSON.stringify({...userFilters, portfolioId: parseInt(action.payload)}));
        },
        setMode: (state, action) => {
            state.filters.mode = action.payload
            localStorage.setItem('userFilters', JSON.stringify({...userFilters, mode: action.payload}));
        },
        setCompanyName: (state, action) => {
            state.filters.companyName = action.payload
        },
        setCheckedEntitlement: (state, action) => {
            state.filters.checkedEntitlement = action.payload
            const startDate = dateToString(startOfDay(subDays(new Date(), getDayConstFromEntitlement(action.payload))));
            const endDate = dateToString(startOfDay(new Date()));
            state.filters.endDate = endDate
            state.filters.startDate = startDate
            localStorage.setItem('userFilters', JSON.stringify({
                ...userFilters,
                selectedEnt: action.payload,
                startDate: startDate,
                endDate: endDate
            }));
        },
        setTopNData: (state, action) => {
            state.topNData = action.payload
        },
        setFilterTags: (state, action) => {
            state.filterTags = action.payload
        },
        addFilterTags: (state, action) => {
            let tag = action.payload
            if (state.filterTags.includes(tag)) return;
            if (state.filterTags.length >= 5) return;
            state.filterTags = [...state.filterTags, action.payload]
        },
        deleteFilterTags: (state, action) => {
            let tag = action.payload
            if (!state.filterTags.includes(tag)) return;
            state.filterTags = state.filterTags.filter(item => item !== tag)
        },
        deleteAllFilterTags: (state, action) => {
            state.filterTags = []
        },
        setFilterType: (state, action) => {
            state.filterType = action.payload
        },
        setUserPortfolios: (state, action) => {
            state.userPortfolios = action.payload
        },
        setIsCollapsed: (state, action) => {
            state.isCollapsed = action.payload
        },
        setUserId: (state, action) => {
            state.userId = action.payload
        },
        setUserEntitlements: (state, action) => {
            state.userEntitlements = action.payload
        },
        setUserName: (state, action) => {
            state.userName = action.payload
        },
        setCompanyDateFilter: (state, action) => {
            state.companyDateFilter = action.payload
        },
        setKeywordList: (state, action) => {
            state.keywordList = action.payload
        }

    },
})

export const {
    setLoading,
    setStartDate,
    setEndDate,
    setPortfolioId,
    setCategory,
    setSubcategory,
    setMode,
    setCheckedEntitlement,
    setUniverseTickers,
    setTopNData,
    addFilterTags,
    deleteFilterTags,
    deleteAllFilterTags,
    setUserPortfolios,
    setIsCollapsed,
    setUserId,
    setUserEntitlements,
    setFilterType,
    setCompanyDateFilter,
    setUserName,
    setKeywordList,
    setFilterTags
} = dashboardSlice.actions

export const selectLoading = state => state.dashboard.loading
export const selectStartDate = state => state.dashboard.filters.startDate
export const selectEndDate = state => state.dashboard.filters.endDate
export const selectPortfolioId = state => state.dashboard.filters.portfolioId
export const selectMode = state => state.dashboard.filters.mode
export const selectFilters = state => state.dashboard.filters
export const selectCompanyName = state => state.dashboard.filters.companyName
export const selectCheckedEntitlement = state => state.dashboard.filters.checkedEntitlement
export const selectTopNData = state => state.dashboard.topNData
export const selectFilterTags = state => state.dashboard.filterTags
export const selectUserPortfolios = state => state.dashboard.userPortfolios
export const selectIsCollapsed = state => state.dashboard.isCollapsed
export const selectCompanyDateFilter = state => state.dashboard.companyDateFilter
export const selectUserId = state => state.dashboard.userId
export const selectUserEntitlements = state => state.dashboard.userEntitlements
export const selectFilterType = state => state.dashboard.filterType
export const selectUserName = state => state.dashboard.userName
export const selectKeywordList = state => state.dashboard.keywordList

export default dashboardSlice.reducer

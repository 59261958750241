import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from './CustomizedAccordions';
import Skeleton from '@mui/material/Skeleton';


export default function LoaderContentTable() {
    return (
        <div className='flex'>
            <div className={`cmp-right-container`}>
                <div className='flex flex-col w-full'>
                    <Accordion expanded={true}>
                        <AccordionSummary  aria-controls="panel1d-content" id="panel1d-header">
                            <Skeleton variant="text" animation="wave" sx={{ fontSize: '18px', width: 120 }} />
                        </AccordionSummary>
                        <AccordionDetails>
                                <div className={`table-row flex-col`}>
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                </div>
                                <div className={`table-row flex-col`}>
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                </div>
                                <div className={`table-row flex-col`}>
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                </div>
                                <div className={`table-row flex-col`}>
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                </div>
                                <div className={`table-row flex-col`}>
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                </div>
                                <div className={`table-row flex-col`}>
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                </div>
                                <div className={`table-row flex-col`}>
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                </div>
                                <div className={`table-row flex-col`}>
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                </div>
                        </AccordionDetails>
                        <AccordionSummary  aria-controls="panel1d-content" id="panel1d-header">
                            <Skeleton variant="text" animation="wave" sx={{ fontSize: '18px', width: 120 }} />
                        </AccordionSummary>
                        <AccordionDetails>
                                <div className={`table-row flex-col`}>
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                </div>
                                <div className={`table-row flex-col`}>
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                </div>
                                <div className={`table-row flex-col`}>
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                </div>
                                <div className={`table-row flex-col`}>
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                </div>
                                <div className={`table-row flex-col`}>
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                </div>
                                <div className={`table-row flex-col`}>
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                </div>
                                <div className={`table-row flex-col`}>
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                </div>
                                <div className={`table-row flex-col`}>
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 320 }} />
                                </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </div>
    );
}

import React, { useState, useEffect } from 'react';
import { getWordCount } from '../../api';
import { useSelector } from 'react-redux';
import { selectFilters, selectUserPortfolios, dashboardSlice } from '../../reducers/dashboardSlice';
import { getSourcesFromEntitlements, getUniqueValues, getNormalizedWeight } from '../../utils';
import WordCloud from '../common/WordCloud';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function WordChartFull() {
  const filter = useSelector(selectFilters);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const userPortfolios = useSelector(selectUserPortfolios);
  const dispatch = useDispatch();

  const handleClick = () => {
    navigate('/words');
  };

  const handlePointClick = (name) => {
    dispatch(dashboardSlice.actions.setFilterType('word'));
    dispatch(dashboardSlice.actions.addFilterTags(name));
  }

  const preprocessing = (data) => {

    data.sort(function (a, b) {
      return b['weight'] - a['weight'];
    });
    data = data.slice(0, 100);

    data = data.map(item => {
      return {
        name: item.text,
        weight: item.size
      };
    });

    return data;
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      if (!userPortfolios) {
        return;
      }
      try {
        let u = userPortfolios.filter(p => p.id == filter.portfolioId);
        if (u.length === 0) {
          u = userPortfolios
        }
        var tickers = getUniqueValues(u[0].symbols);
        const requestBody = {
          'start_date': filter.startDate,
          'end_date': filter.endDate,
          'sources': getSourcesFromEntitlements(filter.checkedEntitlement),
          sentiment_change: filter.mode,
          ticker: tickers,
        }
        const response = await getWordCount(requestBody);
        if (response.result != null) {
          var d = preprocessing(response.result);
          setData(d);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [filter, userPortfolios]);

  return (
    <div className='word-chart-full-container' onClick={handleClick}>
      {loading ? (
        <div className='flex items-center justify-center w-full h-full'>
          <CircularProgress />
        </div>
      ) : (
        <WordCloud chartData={data} handlePointClick={handlePointClick} />
      )}
    </div>
  );
}

export default WordChartFull;
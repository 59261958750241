import React from 'react'
import DailyReports from '../components/events-page/DailyReports';
import TopTables from '../components/events-page/TopTables';
import WordChartFull from '../components/words-page/WordChartFull';


export default function WordsPage() {

    return (
        <div className="universe-container">
            <WordChartFull/>
            <TopTables type="word"/>
            <DailyReports type="word"/>
        </div>
    );
}

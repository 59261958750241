import React, { useRef } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_more from 'highcharts/highcharts-more'
import exporting from 'highcharts/modules/exporting'
import exportData from 'highcharts/modules/export-data'
import accessibility from 'highcharts/modules/accessibility'
HC_more(Highcharts)
exporting(Highcharts)
exportData(Highcharts)
accessibility(Highcharts)


export default function PieChart({ chartData }) {
    const chartRef = useRef(null);
    const options = {
        chart: {
            type: 'pie',
            backgroundColor: 'rgba(245, 245, 245, 0)'
        },
        title: {
            text: null
        },
        exporting: {
            enabled: false
        },
        tooltip: {
            enabled: true,  // Disable tooltip
            formatter: function () {
                return '<b>' + this.point.y + '</b>';
            }
        },
        series: [{
            data: [{
                name: 'Positive',
                y: chartData.posCount,
                color: '#73BF69',
                dataLabels: {
                    enabled: true,
                    distance: -28,
                    format: chartData.sentimentVal,
                    style: {
                        fontSize: '12px',
                        color:  chartData.sentimentVal > 0 ? 'green' : 'red',
                        textOutline: 'none'
                    }
                }
            },
            {
                name: 'Negative',
                y: chartData.negCount,
                color: '#F2495C'
            }],
            center: [15,5],
            size: 61,
            innerSize: '70%',
            showInLegend: false,
            dataLabels: {
                enabled: false,
            }
        }],
        credits: {
            enabled: false
        }
    }

    return  chartData.posCount >= 0 && chartData.negCount >= 0 && (<div className='pie-chart'>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartRef}
                containerProps={{ style: { width: '98%' } }}
            />
        </div>
    );
}
import React, { useState, useEffect } from 'react';
import '../../assets/css/events-page.css';
import { getDailyReports } from '../../api';
import { useSelector } from 'react-redux';
import {selectFilters, selectFilterTags, selectKeywordList, selectUserPortfolios} from '../../reducers/dashboardSlice';
import { getSourcesFromEntitlements , getUniqueValues, getDatesBetween } from '../../utils';
import { CircularProgress } from '@mui/material';
import ColumnChart from '../common/ColumnChart';

function DailyReports({type, tickerName, pageName}) {

    const filter = useSelector(selectFilters);
    const filterTags = useSelector(selectFilterTags);
    const userPortfolios = useSelector(selectUserPortfolios);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const keywords = useSelector(selectKeywordList);

    const preprocessing = (data) => {
        var date = [];
        var positive = [];
        var negative = [];
        var nutral = [];
        var trickers = [];
        var date_final = [];
        var positive_final = [];
        var negative_final = [];
        var nutral_final = [];
        var color_final = [];
        var days = getDatesBetween(filter.startDate, filter.endDate);
      
        Object.entries(data).forEach(([key, value]) => {
          date.push(key);
          positive.push(value.sentiment[0]);
          nutral.push(value.sentiment[1]);
          negative.push(value.sentiment[2]);
          Object.values(value.symbols).forEach((symbol) => {
            trickers.push(symbol);
          });
        });
      
        days.forEach((value) => {
          const index = date.indexOf(value);
          if (index === -1) {
            date_final.push(value);
            positive_final.push(0);
            negative_final.push(0);
            nutral_final.push(0);
          } else {
            date_final.push(date[index]);
            positive_final.push(positive[index]);
            negative_final.push(negative[index]);
            nutral_final.push(nutral[index]);
          }
        });
      
        return {
          date_final,
          positive_final,
          negative_final,
          nutral_final,
          color_final,
          name : filterTags
        };
    }

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            if (!userPortfolios) {
                return;
            }
            try {
                let u = userPortfolios.filter(p => p.id == filter.portfolioId);
                if (u.length === 0) {
                    u = userPortfolios
                }
                var tickers = getUniqueValues(u[0].symbols);
                if (pageName == 'company-page') {
                    tickers = [tickerName];
                }
                const requestBody = {
                    'start_date': filter.startDate,
                    'end_date': filter.endDate,
                    'sources': getSourcesFromEntitlements(filter.checkedEntitlement),
                    'sentiment_change': filter.mode,
                    'universe': tickers,
                    'value': filterTags,
                    'is_keyword_search': (filterTags.length === 1 && !keywords.includes(filterTags[0]))
                }
                const response = await getDailyReports(requestBody, type);
                if (response.data != null) {
                    var d = preprocessing(response.data);
                    var symbols = [];
                    Object.values(response.data).forEach((value) => {
                        Object.values(value.symbols).forEach((symbol) => {
                            symbols.push(symbol);
                        });
                    });
                    symbols = [...new Set(symbols)];
                    window.sessionStorage.setItem('filterTickers', JSON.stringify(symbols));
                    setData(d);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [filter, filterTags, userPortfolios]);

    return (
        <div className='daily-report-full-container'>
            {loading ? (
                <div className='flex items-center justify-center w-full h-full'>
                    <CircularProgress />
                </div>
            ) : (
                <ColumnChart chartData={data} />
            )}
        </div>
    );
}

export default DailyReports;
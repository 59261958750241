import React, { useRef } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_more from 'highcharts/highcharts-more'
import exporting from 'highcharts/modules/exporting'
import exportData from 'highcharts/modules/export-data'
import accessibility from 'highcharts/modules/accessibility'
HC_more(Highcharts)
exporting(Highcharts)
exportData(Highcharts)
accessibility(Highcharts)


export default function ColumnChart(props) {
    const { chartData, plotData } = props;
    console.log(chartData)
    const chartRef = useRef(null);
    const options = {
        chart: {
            type: 'column',
        },
        title: {
            text: chartData.name && chartData.name.length !== 0 ? 'Daily Report ( ' + chartData.name + ' )' : 'Daily Report',
            align: 'left',
            y: 20,
            style: {
                fontSize: '18px',
                fontFamily: 'Roboto',
                fontWeight: '500',
            }
        },
        xAxis: {
            categories: chartData.date_final
        },
        yAxis: {
            title: {
                text: 'Volume'
            },
            plotLines: [{
                value: 0,
                width: 2,
                color: 'grey',
                dashStyle: 'dash'
            }]
        },
        tooltip: {
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:,.0f}</b> ({point.percentage:,.0f}%)<br/>',
            shared: true
        },
        plotOptions: {
            column: {
                stacking: 'normal'
            }
        },
        series: [{
            name: 'Positive',
            data: chartData.positive_final,
            color: '#73bf69',
            showInLegend: false,
        }, {
            name: 'Neutral',
            data: chartData.nutral_final,
            color: '#9b9b9b',
            showInLegend: false,
        }, {
            name: 'Negative',
            data: chartData.negative_final,
            color: '#f2495c',
            showInLegend: false,
        }],
        credits: {
            enabled: false
        },
        lang: {
            noData: "No data for the period selected"
        },
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
            containerProps={{ style: { width: '98%' } }}
        /> 
    );
}
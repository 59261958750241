import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { searchCompanyName } from '../../api';
import { throttle, debounce } from 'lodash';
import {Box} from "@mui/material";


export default function SearchTickerBox() {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  let loading = open && options.length === 0;
  const [companyName, setCompanyName] = useState('');
  const [isInputFocussed, setIsInputFocussed] = useState(false);

  useEffect(() => {
        var searchHistory = JSON.parse(localStorage.getItem('searchHistory')) || [];
        if (searchHistory.length > 0 && typeof searchHistory[0] === 'string') {
          localStorage.setItem('searchHistory', JSON.stringify([]));
          searchHistory = [];
        }
        console.log(searchHistory)
        setOptions(searchHistory)
        // setLoading(open && options.length === 0)
  }, []);

  const throttledAPICall = (searchTerm) => {
    try {
      searchCompanyName(searchTerm).then((response) => {
        if (response !== null && response.result !== null && response.result.length > 0) {
          setOptions(preprocess(response.result));
        } else if (response !== null && response.result !== null && response.result.length === 0) {
          console.log("no results ")
          setOptions([{
            name: 'No results found',
            ticker: '',
            id: '-1'
          }]);
        }
      });
    } catch (error) {
        console.log(error);
    }
  };


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      throttledAPICall(companyName);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [companyName]);

  const onSelectName = (company) => {
    var searchHistory = JSON.parse(localStorage.getItem('searchHistory'));
    if (searchHistory) {
      searchHistory = [{name: company.name, ticker: company.ticker}, ...searchHistory];
      searchHistory = searchHistory.filter((v,i,a)=>a.findIndex(t=>(t.name === v.name && t.ticker === v.ticker))===i)
    } else {
      searchHistory = [{name: company.name, ticker: company.ticker}];
    }
    localStorage.setItem('searchHistory', JSON.stringify(searchHistory));
    window.location.href = '/ticker/' + company.ticker;
  }

  const handleChange = (event) => {
    if (event.target.value.trim() === '') {
      const searchHistory = JSON.parse(localStorage.getItem('searchHistory')) || [];
      setOptions(searchHistory);
    } else {
        setCompanyName(event.target.value);
        setOptions([])
    }
    
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (options.length > 0) {
        onSelectName(options[0]);
      }
    }
  };

  const handleBlur = (event) => {
    setIsInputFocussed(false);
    setCompanyName('')
  }

  const preprocess = (response) => {
    const companyList = [];
    response.forEach((company) => {
      companyList.push({
        name: company.name,
        ticker: company.nticker,
        id: company.id
      });
    });
    companyList.splice(100);
    return companyList;
  }


  return (
    <Autocomplete
      id="asynchronous-demo"
      size={'medium'}
      sx={{ margin: '0 8px', width: 300}}
      open={open}
      onOpen={() => {
        setOpen(options.length > 0);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.ticker + ' (' + option.name + ')' }
      renderOption={(props, option) => (
          <Box component="li" sx={{ fontSize: '13px' }} {...props}>
            <div className="font-semibold mr-2 w-20">{option.ticker}</div>
            <div>{option.name}</div>
          </Box>
      )}
      options={options}
      loading={loading}
      onChange={(event, value) => onSelectName(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search for companies..."
          onFocus={() => setIsInputFocussed(true)}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={companyName}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {/*{params.InputProps.endAdornment}*/}
              </React.Fragment>
            ),
          }}
          sx={{
            input: { color: 'primary', height: '7px'}
          }}
          InputLabelProps={{ shrink: isInputFocussed, sx: {position: 'absolute', top: isInputFocussed ? '0px' : '-7px'}}}
        />
      )}
      componentsProps={{
          paper: {sx: {width: 500}}
      }}
      // ListboxProps={{sx: {width: 500}}}
    />
  );
}

import React, {useState, useEffect} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { blue } from '@mui/material/colors';
import '../../assets/css/app-header.css';
import { DateRangePicker } from "rsuite";
import { subDays, startOfDay } from 'date-fns';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { selectStartDate, dashboardSlice, selectPortfolioId, selectEndDate, selectMode, selectCompanyName, selectUserPortfolios } from '../../reducers/dashboardSlice';
import { useSelector, useDispatch } from 'react-redux';
import { areDatesEqual } from '../../utils';
import { getUserPortfolio } from '../../api';

import SearchTickerBox from './SearchTickerBox';
import { dateToString, stringToDate } from '../../utils';
import SearchWordBox from "./SearchWordBox";

export default function AppHeader() {
    const portfolioId = useSelector(selectPortfolioId);
    const userPortfolios = useSelector(selectUserPortfolios);
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);
    const mode = useSelector(selectMode);
    
    const [portfolioName, setPortfolioName] = useState('');

    const dispatch = useDispatch();

    const handlePortfolioChange = (event) => {
        const { id } = event.currentTarget.dataset;
        if (id != undefined && id != null && id != portfolioId) {
            dispatch(dashboardSlice.actions.setPortfolioId(parseInt(id)));
        }
    }
    const handleModeChange = (event, newMode) => {
        if (mode === newMode || newMode == null || newMode == undefined) { return; }
        dispatch(dashboardSlice.actions.setMode(newMode));
    }
    
    var dateRange = [stringToDate(startDate), stringToDate(endDate)];
    const handleDateRangeChange = (value) => {
        if (value === null) { return; }
        const userFilters = JSON.parse(localStorage.getItem('userFilters'));
        if (value[0] && value[1]) {
            localStorage.setItem('userFilters', JSON.stringify({...userFilters, startDate: dateToString(value[0]), endDate: dateToString(value[1])}));
        }

        if (!areDatesEqual(value[0], startDate)) {
            dispatch(dashboardSlice.actions.setStartDate(dateToString(value[0])));
        }
        if (!areDatesEqual(value[1], endDate)) {
            dispatch(dashboardSlice.actions.setEndDate(dateToString(value[1])));
        }
    }
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await getUserPortfolio();
                if (response != null) {
                    dispatch(dashboardSlice.actions.setUserPortfolios(response.portfolio));
                    dispatch(dashboardSlice.actions.setUserId(response.id));
                    dispatch(dashboardSlice.actions.setUserEntitlements(response.entitlement));
                    dispatch(dashboardSlice.actions.setUserName(response.name));
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (userPortfolios != null && userPortfolios.length > 0) {
            const portfolio = userPortfolios.filter((p) => p.id == portfolioId);
            setPortfolioName(portfolio[0]['name']);
        }
    }, [userPortfolios, portfolioId]);

    return <div className="filter" id='app-header-div'>
        <div className='color-header-wrapper'>
            <FormControl sx={{ minWidth: 180, backgroundColor: '#fff', borderRadius: 1}} color='primary' focused>
                <InputLabel id="demo-simple-select-label" color='primary' sx={{height: '40px'}}>Portfolio</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={portfolioName}
                    label="Portfolio"
                    style={{ color: blue, height: '40px' }}
                >
                    {userPortfolios != null && userPortfolios.length > 0 ? userPortfolios.map((p) => (
                        <MenuItem value={p.name} key={p.id} data-id={p.id} onClick={handlePortfolioChange}>{p.name}</MenuItem>
                    )) : null}
                </Select>
            </FormControl>
            <SearchTickerBox />
            <SearchWordBox />
            <div className='h-9'>
                <DateRangePicker
                    style={{ width: 230, zIndex: 100 }}
                    className='custom-date-range-picker'
                    value={dateRange}
                    onChange={handleDateRangeChange}
                    ranges={[
                        {
                            label: 'Today',
                            value: [startOfDay(subDays(new Date(), 1)), startOfDay(new Date())]
                        },
                        {
                            label: '8D',
                            value: [
                                startOfDay(subDays(new Date(), 8)),
                                startOfDay(new Date())
                            ]
                        },
                        {
                            label: '10D',
                            value: [
                                startOfDay(subDays(new Date(), 10)),
                                startOfDay(new Date())
                            ]
                        },
                        {
                            label: '1M',
                            value: [
                                startOfDay(subDays(new Date(), 30)),
                                startOfDay(new Date())
                            ]
                        },
                        {
                            label: '3M',
                            value: [
                                startOfDay(subDays(new Date(), 90)),
                                startOfDay(new Date())
                            ]
                        },
                        {
                            label: '6M',
                            value: [
                                startOfDay(subDays(new Date(), 180)),
                                startOfDay(new Date())
                            ]
                        },
                        {
                            label: '12M',
                            value: [
                                startOfDay(subDays(new Date(), 365)),
                                startOfDay(new Date())
                            ]
                        },
                        {
                            label: 'YTD',
                            value: [
                                startOfDay(new Date(2023, 0, 1)),
                                startOfDay(new Date())
                            ]
                        }

                    ]}
                />
            </div>
            <div className='mx-2 font-bold'>
                <ToggleButtonGroup
                    color="primary"
                    value={mode}
                    exclusive
                    onChange={handleModeChange}
                    aria-label="Platform"
                    sx={{ backgroundColor: '#ffffff', color: '#fff', height: '39px' }}
                >
                    <ToggleButton value={0} selected={mode === 0}>Sentiment</ToggleButton>
                    <ToggleButton value={1} selected={mode === 1}>Sentiment Change</ToggleButton>
                </ToggleButtonGroup>
            </div>
        </div>
    </div>
}
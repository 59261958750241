import React, {useState, useEffect} from 'react';
import '../../assets/css/sidebar.css';
import Checkbox from '@mui/material/Checkbox';
import {
    callTypes,
    entToKeyMapping,
    headingToEntitlementMapping, keyToCategoryMapping
} from '../../constants';
import '../../assets/css/sidebar.css';
import {useSelector, useDispatch} from 'react-redux';
import {
    dashboardSlice,
    selectCheckedEntitlement,
    selectUserEntitlements,
    setCategory
} from '../../reducers/dashboardSlice';
import logo from '../../assets/images/Earnings_Intel2.png';
import Profile from './Profile';
import favIcon from '../../assets/images/favicon.png';

const Sidebar = () => {
    const [allowedOptions, setAllowedOptions] = useState([]);
    const [collapsed, setCollapsed] = useState(true);
    const [currentCategory, setCurrentCategory] = useState('call');
    const checkedItems = useSelector(selectCheckedEntitlement);
    const userEntitlements = useSelector(selectUserEntitlements);
    const dispatch = useDispatch();

    useEffect(() => {
        if (userEntitlements) {
            const userEntArr = userEntitlements.split(",").map(value => value.trim());
            let allowedEnt = [];
            userEntArr.forEach(ent => {
                allowedEnt = [...allowedEnt, ...entToKeyMapping[ent]]
            });
            setAllowedOptions(allowedEnt);
        }
    }, [userEntitlements]);

    const handleClick = () => {
        window.location.href = `/`;
    };

    const handleCheckboxChange = (item) => {
        let newCheckedItems = new Set(checkedItems)
        const key = item['short_name']
        const category = item['category']
        if (newCheckedItems.size === 1 && newCheckedItems.values().next().value === key) return;
        const currentCategory = getCurrentCategory(checkedItems)
        if (category === 'section') {
            if (newCheckedItems.has('MD') && newCheckedItems.has('QA')) {
                newCheckedItems.delete(key)
            } else {
                newCheckedItems.add(key)
            }
        } else if (category === 'call') {
            if (category !== currentCategory) {
                newCheckedItems = new Set([key, 'MD', 'QA'])
            } else {
                if (newCheckedItems.has(key)) {
                    newCheckedItems.delete(key)
                } else {
                    newCheckedItems.add(key)
                }
            }
        } else if (category === 'news') {
            if (category !== currentCategory) {
                newCheckedItems = new Set([key])
            } else {
                if (newCheckedItems.has(key)) {
                    newCheckedItems.delete(key)
                } else {
                    if (key === 'WB') {
                        newCheckedItems = new Set([key])
                    } else {
                        newCheckedItems.delete('WB')
                        newCheckedItems.add(key)
                    }
                }
            }
        } else {
            newCheckedItems = new Set([key])
        }
        dispatch(dashboardSlice.actions.setCheckedEntitlement(Array.from(newCheckedItems)));
    };
    const getCurrentCategory = (checkedOptions) => {
        if (checkedOptions.includes('MD') || checkedOptions.includes('QA')) {
            return 'call'
        } else if (checkedOptions.includes('WB') || checkedOptions.includes('DJ') || checkedOptions.includes('PR')) {
            return 'news'
        }
        return ''
    }
    const handleToggle = () => {
        setCollapsed(!collapsed);
        dispatch(dashboardSlice.actions.setIsCollapsed(!collapsed));
    };
    const selectAllCall = () => {
        const newCheckedItems = new Set(callTypes.concat('MD', 'QA'))
        dispatch(dashboardSlice.actions.setCheckedEntitlement(Array.from(newCheckedItems)));
    }
    const unselectAllCall = () => {
        const newCheckedItems = new Set(['E', 'MD', 'QA'])
        dispatch(dashboardSlice.actions.setCheckedEntitlement(Array.from(newCheckedItems)));
    }
    const checkBoxCss = {paddingTop: '2px', paddingBottom: '3px', paddingRight: '4px', paddingLeft: 0}
    const entitlementComponent = () => {
        return Object.keys(headingToEntitlementMapping).map((heading, index) => {
            if (heading === 'sections' && getCurrentCategory(checkedItems) !== 'call') return null
            return <div key={index} className="ent-section">
                {heading !== 'sections' && <span className='filter-label'> {heading} </span>}
                {heading === 'sections' && <div className="option-line"></div>}
                {heading === 'calls' && <div className="select-all-link" onClick={selectAllCall}>Select All</div>}
                {heading === 'calls' && <div className="select-all-link" onClick={unselectAllCall}>Unselect</div>}
                {headingToEntitlementMapping[heading].map((item, index) => {
                    let fullName = ''
                    if (collapsed) {
                        fullName = item["short_name"]
                    } else {
                        fullName = item["short_name"] + ' - ' + item["long_name"]
                    }
                    return <div key={index} className="ent-option">
                        <Checkbox
                            disabled={!allowedOptions.includes(item['short_name'])}
                            checked={checkedItems.includes(item['short_name'])}
                            onChange={() => handleCheckboxChange(item)}
                            sx={checkBoxCss}
                        />
                        <div className="ent-title">{fullName}</div>
                    </div>
                })}
            </div>
        })
    }

    return (
        <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
            <div className='filter-container'>
                <div className='logo-container pl-4 pr-4' onClick={handleClick}>
                    {collapsed ? <img src={favIcon} alt='filter' className='logo-small'/> :
                        <img src={logo} alt='filter' className='logo'/>}
                </div>
                {entitlementComponent()}
            </div>
            <Profile isCollapsed={collapsed} handleCollapse={handleToggle}/>
        </div>
    );
};

export default Sidebar;
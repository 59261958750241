import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import '../../assets/css/accounts-page.css';
import TextField from "@mui/material/TextField";
import {createNewUser} from "../../api";


const CreateClientForm = ({authToken, handleBack, changeAccountId}) => {
    // data taken from user input
    const [email, setEmail] = React.useState("");
    const [name, setName] = React.useState("");
    const [companyName, setCompanyName] = React.useState("");
    const [pocName, setPocName] = React.useState("");
    const [pocEmail, setPocEmail] = React.useState("");
    const [apiResponseText, setApiResponseText] = React.useState("")
    const [responseStatus, setResponseStatus] = React.useState(0)
    const [accountId, setAccountId] = React.useState("")

    const submitClientAccountForm = async () => {
        if (email.trim() === "" || name.trim() === "" || companyName.trim() === "" || pocName.trim() === "" || pocEmail.trim() === "") {
            setApiResponseText("ERROR : Please fill all fields")
            setResponseStatus(-1)
            return
        }

        const request = {
            "email": email,
            "name": name,
            "company_name": companyName,
            "poc_name": pocName,
            "poc_email": pocEmail,
            "account_id": accountId.trim()
        }
        const response = await createNewUser(authToken, request);
        if (response['status'] === 1) {
            setResponseStatus(1)
            setApiResponseText(response['message'] + ": " + response.response['user_id'])
            changeAccountId(response.response['user_id'])
            setEmail("")
            setName("")
            setCompanyName("")
            setPocName("")
            setPocEmail("")
        } else {
            setResponseStatus(-1)
            if (response['message'] !== undefined && response['message'].includes("token")) {
                setApiResponseText("ERROR : " + response['message'] + " Please login again.")
                handleBack()
            } else {
                setApiResponseText("ERROR : " + response['message'])
            }
        }
    }



    return (
        <div className="flex">
            <div className='pr-4'>
                {/*<div className='section-headline'> Create Client Account</div>*/}
                <TextField helperText=""
                           value={email}
                           onChange={(e) => setEmail(e.target.value)}
                           label="Email"
                           sx={{width: 300, margin: '10px 10px 10px 0'}}/>
                <TextField helperText=""
                           value={name}
                           onChange={(e) => setName(e.target.value)}
                           label="Name"
                           sx={{width: 300, margin: '10px 10px 10px 0'}}/>
                <TextField helperText=""
                           value={companyName}
                           onChange={(e) => setCompanyName(e.target.value)}
                           label="Company Name"
                           sx={{width: 300, margin: '10px 10px 10px 0'}}/>
                <TextField helperText=""
                           value={pocName}
                           onChange={(e) => setPocName(e.target.value)}
                           label="POC Name"
                           sx={{width: 300, margin: '10px 10px 10px 0'}}/>
                <TextField helperText=""
                           value={pocEmail}
                           onChange={(e) => setPocEmail(e.target.value)}
                           label="POC Email"
                           sx={{width: 300, margin: '10px 10px 10px 0'}}/>
                <TextField helperText=""
                           value={accountId}
                           onChange={(e) => setAccountId(e.target.value)}
                           label="Account ID (Optional)"
                           sx={{width: 300, margin: '10px 10px 10px 0'}}/>

                <Box sx={{mb: 2}}>
                    <div>
                        <Button
                            variant="contained"
                            onClick={() => submitClientAccountForm()}
                            sx={{mt: 1, mr: 1}}
                            // disabled={disableMasterBtn}
                        >
                            Create Client Account
                        </Button>
                        {/*<Button*/}
                        {/*    onClick={handleBack}*/}
                        {/*    sx={{mt: 1, mr: 1}}*/}
                        {/*>*/}
                        {/*    Back to login*/}
                        {/*</Button>*/}
                    </div>

                </Box>
                {
                    apiResponseText !== "" &&
                    <div className={`api-response-text ${responseStatus === 1 ? 'success' : 'error'}`}>
                        {apiResponseText}
                    </div>
                }
            </div>
        </div>
    );
};

export default CreateClientForm;
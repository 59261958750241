import React, {useState} from 'react';
import ExpandRightIcon from '../../assets/images/expand-right.png';
import {FiSettings, FiHelpCircle} from 'react-icons/fi';
import {FaRegUserCircle} from 'react-icons/fa';
import {MdOutlineManageHistory} from 'react-icons/md';
import {BiLogOutCircle} from 'react-icons/bi';
import {baseUrl} from '../../constants';
import {selectUserName} from "../../reducers/dashboardSlice";
import {useSelector} from "react-redux";
import {logoutApi} from "../../api";

export default function Profile({isCollapsed, handleCollapse}) {
    const [isOpen, setIsOpen] = useState(false);
    const userName = useSelector(selectUserName);
    const userSymbol = userName ? userName.charAt(0).toUpperCase() : '';

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };


    return !isCollapsed ?
        <div className="profile-container-expanded">
            <div className="expand-right-ic-container" onClick={handleCollapse}>
                <img src={ExpandRightIcon} className="expand-right-ic-expanded"/>
            </div>
            <div className="profile-ic-container-expanded">
                <div className="profile-ic-expanded" onClick={handleToggle}>
                    {userSymbol}
                </div>
                <div className="profile-name">
                    {userName}
                </div>
            </div>
            {isOpen && <div
                className="dropdown-container absolute left-0 z-20 w-full overflow-hidden rounded-md bg-gray-950 pt-1 opacity-100 translate-y-0">
                <nav role="none">
                    <a className="flex p-3 items-center gap-3 transition-colors duration-200 text-white cursor-pointer hover:bg-gray-700"
                       href={`${baseUrl}/Profile`} title='user profile'>
                        <FaRegUserCircle style={{width: '16px', height: '16px'}}/>
                        {!isCollapsed && 'User Profile'}</a>
                    <div className="my-1.5 h-px bg-white/20" role="none"></div>
                    <a className="flex p-3 items-center gap-3 transition-colors duration-200 text-white cursor-pointer hover:bg-gray-700"
                       href={`${baseUrl}/PortfolioManager`} title='manage portfolio'>
                        <MdOutlineManageHistory style={{width: '16px', height: '16px'}}/>
                        {!isCollapsed && 'Manage Portfolio'}</a>
                    <div className="my-1.5 h-px bg-white/20" role="none"></div>
                    <a className="flex p-3 items-center gap-3 transition-colors duration-200 text-white cursor-pointer hover:bg-gray-700"
                       href={`${baseUrl}/help`} title='help'>
                        <FiHelpCircle style={{width: '16px', height: '16px'}}/>
                        {!isCollapsed && 'Help'}</a>
                    <div className="my-1.5 h-px bg-white/20" role="none"></div>
                    <div className="flex p-3 items-center gap-3 transition-colors duration-200 text-white cursor-pointer hover:bg-gray-700"
                       onClick={logoutApi} title='logout'>
                        <BiLogOutCircle style={{width: '16px', height: '16px'}}/>
                        {!isCollapsed && 'Log out'}</div>
                </nav>
            </div>}
        </div> : <div className="profile-container">
            <div className="expand-right-ic-container" onClick={handleCollapse}>
                <img src={ExpandRightIcon} className="expand-right-ic"/>
            </div>
            <div className="profile-ic-container">
                <div className="profile-ic" onClick={handleToggle}>
                    {userSymbol}
                </div>
            </div>
            {isOpen && <div
                className="dropdown-container absolute left-0 z-20 w-full overflow-hidden rounded-md bg-gray-950 pt-1 opacity-100 translate-y-0">
                <nav role="none">
                    <a className="flex p-3 items-center gap-3 transition-colors duration-200 text-white cursor-pointer hover:bg-gray-700"
                       href={`${baseUrl}/Profile`} title='user profile'>
                        <FaRegUserCircle style={{width: '16px', height: '16px'}}/>
                        {!isCollapsed && 'User Profile'}</a>
                    <div className="my-1.5 h-px bg-white/20" role="none"></div>
                    <a className="flex p-3 items-center gap-3 transition-colors duration-200 text-white cursor-pointer hover:bg-gray-700"
                       href={`${baseUrl}/PortfolioManager`} title='manage portfolio'>
                        <MdOutlineManageHistory style={{width: '16px', height: '16px'}}/>
                        {!isCollapsed && 'Manage Portfolio'}</a>
                    <div className="my-1.5 h-px bg-white/20" role="none"></div>
                    <a className="flex p-3 items-center gap-3 transition-colors duration-200 text-white cursor-pointer hover:bg-gray-700"
                       href={`${baseUrl}/help`} title='help'>
                        <FiHelpCircle style={{width: '16px', height: '16px'}}/>
                        {!isCollapsed && 'Help'}</a>
                    <div className="my-1.5 h-px bg-white/20" role="none"></div>
                    <div className="flex p-3 items-center gap-3 transition-colors duration-200 text-white cursor-pointer hover:bg-gray-700"
                       onClick={logoutApi} title='logout'>
                        <BiLogOutCircle style={{width: '16px', height: '16px'}}/>
                        {!isCollapsed && 'Log out'}</div>
                </nav>
            </div>}
        </div>;

    // return (
    //     <div className="settings-container">
    //         {isOpen && <div className="dropdown-container absolute left-0 z-20 w-full overflow-hidden rounded-md bg-gray-950 pt-1 opacity-100 translate-y-0">
    //             <nav role="none">
    //                     <a className="flex p-3 items-center gap-3 transition-colors duration-200 text-white cursor-pointer hover:bg-gray-700" href={`${baseUrl}/Profile`} title='user profile'>
    //                         <FaRegUserCircle style={{ width: '16px', height: '16px' }} />
    //                         {!isCollapsed && 'User Profile'}</a>
    //                 <div className="my-1.5 h-px bg-white/20" role="none"></div>
    //                     <a className="flex p-3 items-center gap-3 transition-colors duration-200 text-white cursor-pointer hover:bg-gray-700" href={`${baseUrl}/PortfolioManager`} title='manage portfolio'>
    //                         <MdOutlineManageHistory style={{ width: '16px', height: '16px' }} />
    //                         {!isCollapsed && 'Manage Portfolio'}</a>
    //                 <div className="my-1.5 h-px bg-white/20" role="none"></div>
    //                     <a className="flex p-3 items-center gap-3 transition-colors duration-200 text-white cursor-pointer hover:bg-gray-700" href={`${baseUrl}/help`} title='help'>
    //                         <FiHelpCircle style={{ width: '16px', height: '16px' }} />
    //                         {!isCollapsed && 'Help'}</a>
    //                 <div className="my-1.5 h-px bg-white/20" role="none"></div>
    //                     <a className="flex p-3 items-center gap-3 transition-colors duration-200 text-white cursor-pointer hover:bg-gray-700" href={`${baseUrl}/logout`} title='logout'>
    //                         <BiLogOutCircle style={{ width: '16px', height: '16px' }} />
    //                         {!isCollapsed && 'Log out'}</a>
    //             </nav>
    //         </div>}
    //
    //         <div className="profile-ic-container">
    //             <img className="profile-ic"/>
    //             <FiSettings className="settings-icon" />
    //         </div>
    //         {!isCollapsed && <div className="accordion-header" onClick={handleToggle}>
    //             <FiSettings className="settings-icon" />
    //             <span className="settings-text">Settings</span>
    //         </div>}
    //     </div>
    // );
}
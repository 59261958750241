import React, { useEffect, useState } from 'react';
import '../../assets/css/company-page.css';
import AutoFixHighSharpIcon from '@mui/icons-material/AutoFixHighSharp';
import CloseIcon from '@mui/icons-material/Close';
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import { indigo } from '@mui/material/colors';
import Input from '@mui/material/Input';


export default function Popover({modalPosition, onEditClick}) {
    return (
        <div
            style={{
                top: modalPosition.top,
                left: modalPosition.left
            }}
            onClick={onEditClick}
            className={`popover-container`}
        >
            <AutoFixHighSharpIcon />
        </div>
    );
}

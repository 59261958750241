import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import '../../assets/css/metadata-sidebar.css';
import Skeleton from '@mui/material/Skeleton';

const LoaderSidebar = () => {
    return (
        <div className='metadata-container'>
            <div className='modal-header'>
                <div>
                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 40 }} />
                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '18px', width: 120 }} />
                </div>
                <div className='modal-actions'>
                    <CloseIcon color='error' sx={{ fontSize: '30px' }} />
                </div>
            </div>
            <div className='metadata-content'>
                <div className='mb-2'>
                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 40 }} />
                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 120 }} />
                </div>
                <div className='mb-2'>
                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 40 }} />
                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '13px', width: 120 }} />
                </div>
                <div className='mb-2'>
                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '18px', width: 60 }} />
                    <div className='flex'>
                        <Skeleton variant="rectangular" animation="wave" sx={{ width: 20, height: 20, marginRight: '20px' }} />
                        <Skeleton variant="rectangular" animation="wave" sx={{ width: 20, height: 20, marginRight: '20px' }} />
                        <Skeleton variant="rectangular" animation="wave" sx={{ width: 20, height: 20, marginRight: '20px' }} />
                    </div>
                </div>
                <div className='mb-2'>
                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '18px', width: 60 }} />
                    <div className='flex'>
                        <Skeleton variant="rectangular" animation="wave" sx={{ width: 20, height: 20, marginRight: '20px' }} />
                        <Skeleton variant="rectangular" animation="wave" sx={{ width: 20, height: 20, marginRight: '20px' }} />
                        <Skeleton variant="rectangular" animation="wave" sx={{ width: 20, height: 20, marginRight: '20px' }} />
                    </div>
                </div>
                <div className='mb-2'>
                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '18px', width: 60 }} />
                    <div className='flex'>
                        <Skeleton variant="rectangular" animation="wave" sx={{ width: 20, height: 20, marginRight: '20px' }} />
                        <Skeleton variant="rectangular" animation="wave" sx={{ width: 20, height: 20, marginRight: '20px' }} />
                        <Skeleton variant="rectangular" animation="wave" sx={{ width: 20, height: 20, marginRight: '20px' }} />
                    </div>
                </div>
                <div>
                    <Skeleton variant="rectangular" animation="wave" sx={{ width: 77, height: 30, marginTop: '20px' }} />
                </div>
            </div>
        </div>
    );
};

export default LoaderSidebar;
import React, { useState, useEffect } from 'react';
import { getWordCount } from '../../api';
import { useSelector } from 'react-redux';
import {
  selectFilters,
  dashboardSlice,
  selectCompanyDateFilter,
    selectFilterType,
} from '../../reducers/dashboardSlice';
import { getSourcesFromEntitlements, getPreviousDate } from '../../utils';
import WordCloud from '../common/WordCloud';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';

export default function CompWordChart({ tickerName }) {
  const filter = useSelector(selectFilters);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const dateFilter = useSelector(selectCompanyDateFilter);
    const filterType = useSelector(selectFilterType);
  const preprocessing = (data) => {

    data.sort(function (a, b) {
      return b['weight'] - a['weight'];
    });
    data = data.slice(0, 100);

    let keywordList = []
    data = data.map(item => {
      keywordList.push(item.text)
      return {
        name: item.text,
        weight: item.size
      };
    });

    dispatch(dashboardSlice.actions.setKeywordList(keywordList))
    return data;
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        var from_date = filter.startDate;
        var to_date = filter.endDate;
        if (dateFilter !== '') {
          from_date = getPreviousDate(dateFilter);
          to_date = dateFilter;
        }
        const requestBody = {
          'start_date': from_date,
          'end_date': to_date,
          'sources': getSourcesFromEntitlements(filter.checkedEntitlement),
          sentiment_change: filter.mode,
          ticker: [tickerName],
        }
        const response = await getWordCount(requestBody);
        if (response.result != null) {
          var d = preprocessing(response.result);
          setData(d);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [filter, dateFilter]);

  const onWordClick = (word) => {
    if (filterType === 'event') {
      dispatch(dashboardSlice.actions.deleteAllFilterTags())
    }
    dispatch(dashboardSlice.actions.setFilterType('word'));
    dispatch(dashboardSlice.actions.addFilterTags(word));
    dispatch(dashboardSlice.actions.setCompanyDateFilter(''));
  };

  return (
    <div className='word-chart-full-container mb-2'>
      {loading ? (
        <div className='flex items-center justify-center w-full h-full'>
          <CircularProgress />
        </div>
      ) : (
        <WordCloud chartData={data} handlePointClick={onWordClick} />
      )}
    </div>
  );
}
import React, {useEffect, useState} from 'react';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, TableSortLabel } from '@mui/material';
import SentimentLine from './SentimentLine';
import { useNavigate } from 'react-router-dom';

const headCells = [
  { id: 'ticker', label: 'Tickers' },
  { id: 'company', label: 'Company Name' },
  { id: 'volume', label: 'Count' },
  { id: 'return', label: 'Return' },
  { id: 'sentiment', label: 'Sentiment' }
];

const TableWrapper = (props) => {
  
  const {chartData, titleData, orderByProp, orderProp} = props;
  const [orderBy, setOrderBy] = useState(orderByProp || 'sentiment');
  const [order, setOrder] = useState(orderProp || 'desc');
  const [sortedData, setSortedData] = useState(chartData);
  const navigate = useNavigate();


  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleTickerClick = (ticker) => () => {
    navigate(`/ticker/${ticker}`);
  }

  useEffect(() => {
    if (chartData !== null) {
        var a = [...chartData];
        const s = a.sort((a, b) => {
          const isAsc = order === 'asc';
          if (orderBy === 'sentiment' || orderBy === 'return' || orderBy === 'volume') {
            return isAsc ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
          } else {
            return isAsc ? a[orderBy].localeCompare(b[orderBy]) : b[orderBy].localeCompare(a[orderBy]);
          }
        });
        setSortedData(s);
    }
  }, [chartData, order, orderBy])
  
  return (
    <div className='w-full'>
    <div className=' table-title-bar'>
      {titleData}
    </div>
    <TableContainer component={Paper} style={{ maxHeight: '350px' }}>
      <Table stickyHeader size='small'>
        <TableHead>
          <TableRow>
          {headCells.map((headCell) => (
              <TableCell key={headCell.id} sx={{ backgroundColor: '#e0e0e0', fontWeight: '900' }}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={() => handleSort(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((row, index) => (
            <TableRow key={index}>
              <TableCell onClick={handleTickerClick(row.ticker)} sx={{ fontWeight: 'bold', color: 'info.main', cursor: 'pointer' }}>{row.ticker}</TableCell>
              <TableCell>{row.company}</TableCell>
              <TableCell>{row.volume}</TableCell>
              <TableCell>{(row.return*100).toFixed(2)}%</TableCell>
              <TableCell>
                <SentimentLine sentiment={row.sentiment.toFixed(2)}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
};

export default TableWrapper;
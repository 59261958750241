import React from 'react'
import {useSelector} from 'react-redux';
import EventChartFull from '../components/events-page/EventChartFull'
import DailyReports from '../components/events-page/DailyReports';
import TopTables from '../components/events-page/TopTables';
import {selectFilterTags, dashboardSlice} from '../reducers/dashboardSlice';
import Chip from '@mui/material/Chip';
import {useDispatch} from 'react-redux';
import {isArrayNotEmpty} from "../utils";

import Stack from '@mui/material/Stack';

export default function EventsPage() {

    return (
        <div className="universe-container">
            <EventChartFull/>
            <DailyReports type={"event"}/>
            <TopTables/>
        </div>
    );
}

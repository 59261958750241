import React, { useState, useEffect } from 'react';
import '../../assets/css/events-page.css';
import { CircularProgress } from '@mui/material';
import ColumnLinePieChart from './ColumnLinePieChart';
import { useDispatch } from 'react-redux';
import { dashboardSlice} from '../../reducers/dashboardSlice';

export default function PriceSentimentChart({chartData}) {

    const [loading, setLoading] = useState(false);
    // const [selectedDate, setSelectedDate] = useState('');
    const dispatch = useDispatch();
    useEffect(() => {
        if (chartData === undefined) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [chartData]);

    const onDateClick = (date) => {
        dispatch(dashboardSlice.actions.setFilterType('date'));
        dispatch(dashboardSlice.actions.deleteAllFilterTags());
        dispatch(dashboardSlice.actions.setCompanyDateFilter(date));
    };

    console.log(chartData)

    return (
        <div className='event-chart-full-container mb-2'>
            {loading ? (
                <div className='flex items-center justify-center w-full h-full'>
                    <CircularProgress />
                </div>
            ) : (
                <ColumnLinePieChart chartData={chartData} onColumnClick={onDateClick}/>
            )}
        </div>
    );
}
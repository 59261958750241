import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import '../assets/css/accounts-page.css';
import {
    adminLogin,
    getSubscriptionsApi
} from '../api';
import TextField from "@mui/material/TextField";
import Divider from '@mui/material/Divider';
import {DataGrid} from "@mui/x-data-grid";
import CreateClientForm from "../components/file-share-page/CreateClientForm";
import CreateSubscriptionForm from "../components/file-share-page/CreateSubscriptionForm";
import ClientFileTable from "../components/file-share-page/ClientFileTable";


const FileSharePage = () => {
    // data taken from user input
    const [activeStep, setActiveStep] = React.useState(0);
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [accountID, setAccountID] = React.useState("");
    const [refreshCounter, setRefreshCounter] = React.useState(0);

    // data gotten from api response
    const [authToken, setAuthToken] = React.useState("");
    const [disableMasterBtn, setDisableMasterBtn] = React.useState(false);

    const [toggleAccount, setToggleAccount] = React.useState(false)

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const submitLoginForm = async () => {
        const response = await adminLogin(username.toUpperCase(), password);
        if (response !== {} && response['status'] === 1) {
            // save authToken to redux store
            setAuthToken(response['response']['auth_token'])
            handleNext();
        } else {
            // display error message
            console.error('error logging in')
        }
    }

    const redirectToAccountsList = () => {
        window.location.href = '/admin/file_share/users'
    }


    return (
        <div className='page-container'>
            <Box>
                <Stepper activeStep={activeStep} orientation="vertical">
                    <Step key={"login"}>
                        <StepLabel>Enter Admin Credentials</StepLabel>
                        <StepContent>
                            <div className='app-header'>
                                <div>Username</div>
                                <input type='text' value={username} onChange={(e) => setUsername(e.target.value)}/>
                                <div>Password</div>
                                <input type='password' value={password} onChange={(e) => setPassword(e.target.value)}/>
                            </div>
                            <Box sx={{mb: 2}}>
                                <div>
                                    <Button
                                        variant="contained"
                                        onClick={() => submitLoginForm(username, password)}
                                        sx={{mt: 1, mr: 1}}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                    <Step key={"account-creation"}>
                        <StepLabel>Send archive or list files</StepLabel>
                        <StepContent>
                            <Button
                                variant="outlined"
                                onClick={() => setToggleAccount(!toggleAccount)}
                                sx={{mt: 1, mr: 1, height: 50}}
                                disabled={disableMasterBtn}
                            >
                                Create Account
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => redirectToAccountsList()}
                                sx={{mt: 1, mr: 1, height: 30}}
                                disabled={disableMasterBtn}
                            >
                                All Accounts
                            </Button>
                            {toggleAccount && <CreateClientForm authToken={authToken} handleBack={handleBack} changeAccountId={setAccountID}/>}
                            <ClientFileTable authToken={authToken} handleBack={handleBack} changeAccountId={setAccountID} refreshCounter={refreshCounter} parAccountId={accountID}/>
                            <Divider sx={{my: 2}}/>
                            <CreateSubscriptionForm authToken={authToken} handleBack={handleBack}
                                                    accountId={accountID} setRefreshCounter={setRefreshCounter}/>


                        </StepContent>
                    </Step>
                </Stepper>
                {activeStep === 2 && (
                    <Paper square elevation={0} sx={{p: 3}}>
                        <Typography>All steps completed - you&apos;re finished</Typography>
                        <Button onClick={handleReset} sx={{mt: 1, mr: 1}}>
                            Reset
                        </Button>
                    </Paper>
                )}
            </Box>
        </div>
    );
};

export default FileSharePage;
import React, { useEffect, useState } from 'react';
import '../../assets/css/company-page.css';
import { getQuerySolrTranscripts, getUserPortfolio } from '../../api';
import {getPreviousDate, getSourcesFromEntitlements, isCallEnt} from '../../utils';
import { useSelector } from 'react-redux';
import {
    selectFilters,
    selectCompanyDateFilter,
    selectFilterTags, selectFilterType
} from '../../reducers/dashboardSlice';
import MetadataSidebar from './MetadataSidebar';
import Popover from './Popover';
import Typography from '@mui/material/Typography';
import { Accordion, AccordionDetails, AccordionSummary } from './CustomizedAccordions';
import LoaderContentTable from './LoaderContentTable';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';


export default function ContentTable() {
    const filter = useSelector(selectFilters);
    const dateFilter = useSelector(selectCompanyDateFilter);
    const filterTags = useSelector(selectFilterTags);
    const filterType = useSelector(selectFilterType);
    const tickerName = window.location.pathname.split('/')[2];
    const isCall = isCallEnt(filter.checkedEntitlement);
    const [expandedSectionHeaders, setExpandedSectionHeaders] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);

    const [selectedText, setSelectedText] = useState('');
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
    const [showModal, setShowModal] = useState(false);
    const [showEditBox, setEditBox] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggleRow = (index, key, isBody) => {
        if (isCall) { return; }
        if (!isBody) return;
        const id = data[key][index].id;
        setExpandedRows(expandedRows.includes(id)
            ? expandedRows.filter((i) => i !== id)
            : [...expandedRows, id]
        );
    };

    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            if (!tickerName) {
                return;
            }
            try {
                setSelectedRow(null);
                setExpandedRows([]);

                var type = filterType === "word" ? "word" : "event";
                var eventv4 = filterTags;
                var fromDate = filter.startDate;
                var toDate = filter.endDate;

                if (dateFilter !== '') {
                    fromDate = getPreviousDate(dateFilter);
                    toDate = dateFilter;
                }

                var userId = null
                try {
                    const response = await getUserPortfolio();
                    if (response != null) {
                        userId = response.id
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
                const requestBody = {
                    'from': fromDate,
                    'to': toDate,
                    'sources': getSourcesFromEntitlements(filter.checkedEntitlement),
                    'sentiment_change': filter.mode,
                    'ticker': [tickerName],
                    'offset': 0,
                    'events_v4': eventv4,
                    'type': type,
                    'userid': parseInt(userId),
                    'counter': window.sessionStorage.getItem('annotationCounter') ? parseInt(window.sessionStorage.getItem('annotationCounter')) : 0
                }
                const response = await getQuerySolrTranscripts(requestBody);
                if (response.data != null) {
                    setData(response.data);
                    setLoading(false);
                    const expandedSectionHeaders = Object.keys(response.data).map((key, index) => {
                        return index;
                    });
                    setExpandedSectionHeaders(expandedSectionHeaders);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
        
    }, [filter, dateFilter, filterTags]);

    const onCloseSidebar = () => setSelectedRow(null);
    const handleTextSelection = () => {
        const selection = window.getSelection();
        const selectedString = selection.toString();
        if (selectedString) {
            const range = selection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            const position = {
                top: rect.bottom + window.pageYOffset,
                left: rect.left + window.pageXOffset,
            };
            setSelectedText(selectedString);
            setModalPosition(position);
            setShowModal(true);
            setEditBox(false);
        } else {
            setShowModal(false);
            setSelectedText('');
            setEditBox(false);
        }
    };
    const onEditClick = () => {
        setEditBox(true);
        setShowModal(false);
    }
    const onSaveClick = () => setEditBox(false);

    const toggleSectionHeader = (index) => {
        const isExpanded = expandedSectionHeaders.includes(index);
        if (isExpanded) {
            const panel = expandedSectionHeaders.filter((i) => i !== index);
            setExpandedSectionHeaders(panel);
        } else {
            setExpandedSectionHeaders([...expandedSectionHeaders, index]);
        }
    };

    const count = Object.keys(data).length;
//     const tbleHeightDiff = () => {
//         const diff = 140 + count * 50;
//         if (diff > 800) {
//             return 500;
//         }
//         return diff;
//     }
    const selectedRowCheck = (id) => {
        if (expandedRows && expandedRows.length > 0) {
            return expandedRows.includes(id);
        } 
        return false
    }
    const isRowSelected = (id) => {
        if (selectedRow && selectedRow.id === id) {
            return true;
        }
        return false;
    }

    const makeRowElem = (body, row) => {
        return {
            id: row.id ,
            section: body['section_id'],
            version: ''
        }
    }
    return (loading ? <LoaderContentTable /> :
        <div className='flex'>
            <div className={`cmp-right-container ${selectedRow ? 'collapsed' : ''}`}>
                <div className='flex flex-col w-full'>
                    {data && Object.keys(data).map((key, index) => {
                        var heading = key
                        var rowData = data[key]
                        return (
                            <Accordion expanded={expandedSectionHeaders.includes(index)} onChange={() => toggleSectionHeader(index)} key={index}
                                       sx={{border: 0}}>
                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                                sx={{minHeight: '20px', borderBottom: 0, position: 'sticky', top: 0, zIndex: 6}}>
                                    <Typography fontWeight='bold'>{heading}</Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{ padding: 0 }}>
                                    {rowData.map((row, index) => {
                                        return (
                                            <div className={`row-container ${isRowSelected(row.id) ? 'selected' : ''}`} key={index}>
                                                <div
                                                    className={`table-row ${row.sentiment == 1 ? 'positive' : (row.sentiment == -1) ? 'negative' : ''} ${expandedRows.includes(index) && !isCall ? 'expanded' : ''} ${isRowSelected(row.id) ? 'selected' : ''}`}
                                                    onClick={() => setSelectedRow(row)}
                                                >
                                                    <div style={{ display: 'flex' }} >
                                                        <ChevronRightRoundedIcon
                                                            className={`expand-arrow ${selectedRowCheck(row.id) ? 'expanded' : ''}`}
                                                            sx={{fontSize: '20px'}}
                                                            onClick={() => toggleRow(index, key, row.body.length > 0)}/>
                                                        <span>{(isCall) ? row.section : row.timestamp.split(' ')[1].split('.')[0]}</span>
                                                    </div>
                                                    <div style={{ width: '86%', marginLeft: '8px' }} onMouseUp={handleTextSelection} onBlur={() => setShowModal(false)}>{row.heading}</div>
                                                </div>
                                                {row.body.length > 0 && selectedRowCheck(row.id) && row.body.map((bodyElem, index) => {
                                                    var sentiment = bodyElem.sentiment;
                                                    return (<div
                                                        className={`body-row ${sentiment == 1 ? 'positive' : (sentiment == -1) ? 'negative' : ''}`}
                                                        onClick={() => setSelectedRow(makeRowElem(bodyElem, row))}
                                                        key={index}
                                                    >
                                                        <div style={{ width: '5%' }} className={`metadata-col1 ${sentiment == 1 ? 'positive' : (sentiment == -1) ? 'negative' : ''}`}></div>
                                                        <div style={{ width: '94%' }} dangerouslySetInnerHTML={{ __html: bodyElem.text }} onMouseUp={handleTextSelection}></div>
                                                    </div>)
                                                })}
                                            </div>
                                        )
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                </div>
            </div>
            {selectedRow && <MetadataSidebar data={selectedRow}
                onClose={onCloseSidebar} showEditBox={showEditBox}
                selectedText={selectedText} onSaveClick={onSaveClick} />}
            {showModal &&
                <Popover modalPosition={modalPosition} onEditClick={onEditClick} />}
        </div>
    );
}



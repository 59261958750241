import React, {useState, useEffect, useRef} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {useDispatch, useSelector} from "react-redux";
import {dashboardSlice, selectKeywordList} from "../../reducers/dashboardSlice";
import Chip from "@mui/material/Chip";
import {useNavigate} from "react-router-dom";


export default function SearchWordBox() {
    const keywords = useSelector(selectKeywordList);
    const [options, setOptions] = useState([]);
    const [isInputFocussed, setIsInputFocussed] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
            setOptions(keywords);
        }
    )

    const handleChange = (event, words) => {
        console.log(event)
        console.log(words)
        if (words && words.length > 0) {
            dispatch(dashboardSlice.actions.setFilterType('word'));
            dispatch(dashboardSlice.actions.addFilterTags(words));
            // get the current url
            const currentUrl = window.location.href;
            if (!currentUrl.includes('ticker')) {
                navigate('/words')
            }
        }
    }

    return (
        <Autocomplete
            // multiple
            size={'medium'}
            id="tags-filled"
            freeSolo
            options={options}
            sx={{width: 300, margin: '0 8px 0 0'}}
            renderTags={(value, getTagProps) =>
                value.filter(option => keywords.includes(option)).map((option, index) => (
                    <Chip
                        variant="filled"
                        label={option}
                        sx={{textTransform: 'capitalize', height: '16px', fontSize: '12px', margin: '0 4px 0 0'}}
                        key={index}
                    />)
                )
            }
            onChange={handleChange}
            clearOnBlur={true}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label="Search Keywords"
                    onFocus={() => setIsInputFocussed(true)}
                    onBlur={() => setIsInputFocussed(false)}
                    sx={{input: {color: 'primary', height: '7px'},}}
                    InputLabelProps={{
                        sx: {position: 'absolute', top: isInputFocussed ? '0px' : '-7px'}
                    }}/>}
        />
    );
}

import React, {useState, useEffect} from 'react';
import '../../assets/css/filter-bar.css';
import {isArrayNotEmpty} from "../../utils";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import {useDispatch, useSelector} from "react-redux";
import {dashboardSlice, selectFilterTags} from "../../reducers/dashboardSlice";

export default function FilterBar() {
    const filterTags = useSelector(selectFilterTags);
    const dispatch = useDispatch();

    const handleDelete = (tag) => {
        dispatch(dashboardSlice.actions.deleteFilterTags(tag));
    }


    return isArrayNotEmpty(filterTags) ? <div className="filter-bar-container">
        <div className='mr-4 text-sm'>FILTERS APPLIED   </div>
        <Stack direction="row" spacing={1}>
            {isArrayNotEmpty(filterTags) ? filterTags.map((tag, index) => <Chip color="primary" key={index}
                                                                         onDelete={() => handleDelete(tag)}
                                                                         label={tag}
                                                                         sx={{textTransform: 'capitalize', height: '26px'}}/>) : null}
        </Stack>
    </div> : <div className='empty-filter-bar-container'></div>;
}
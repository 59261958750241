import React, { useState, useEffect } from 'react';
import '../../assets/css/company-page.css';
import { getEventsForCompany } from '../../api';
import { useSelector } from 'react-redux';
import { selectFilters, dashboardSlice, selectFilterType, selectCompanyDateFilter } from '../../reducers/dashboardSlice';
import { dateToString, getColorHardcoded, findPercentileRank, getPreviousDate, getSourcesFromEntitlements } from '../../utils';
import TreemapChart from '../common/TreemapChart';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';

export default function CompEventChart({tickerName}) {

    const filter = useSelector(selectFilters);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const dateFilter = useSelector(selectCompanyDateFilter);
    const filterType = useSelector(selectFilterType);

    const preprocessing = (data) => {
        var parent = [
            {
                id: "positive",
                name: "positive",
            },
            {
                id: "negative",
                name: "negative",
            },
            {
                id: "neutral",
                name: "neutral",
            }
        ]
        var sortedVolumeData = data.map((item) => item.volume).sort((a, b) => a - b);
        var totalValues = sortedVolumeData.length;

        data = data.map(item => {
            return {
                name: item.event,
                volume: item.volume,
                value: findPercentileRank(sortedVolumeData, item.volume, totalValues),
                sentiment: item.sentiment,
                color: getColorHardcoded(item.sentiment),
                parent: item.sentiment > 0 ? "positive" : item.sentiment < 0 ? "negative" : "neutral"
            };
        });

        var chartData = parent.concat(data);
        return chartData;
    }

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                var from_date = filter.startDate;
                var to_date = filter.endDate;
                if (dateFilter !== '') {
                    from_date = getPreviousDate(dateFilter);
                    to_date = dateFilter;
                }
                const requestBody = {
                    'from': from_date,
                    'to': to_date,
                    'sources': getSourcesFromEntitlements(filter.checkedEntitlement),
                    sentiment_change: filter.mode,
                    ticker: tickerName,
                    frequency: "daily"
                }
                const response = await getEventsForCompany(requestBody);
                if (response.result != null) {
                    var d = preprocessing(response.result);
                    setData(d);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [filter, dateFilter]);

    const onEventClick = (event) => {
        if (filterType === 'word') {
            dispatch(dashboardSlice.actions.deleteAllFilterTags())
        }
        dispatch(dashboardSlice.actions.setFilterType('event'));
        dispatch(dashboardSlice.actions.addFilterTags(event));
        dispatch(dashboardSlice.actions.setCompanyDateFilter(''));
    };

    return (
        <div className='event-chart-full-container mb-2'>
            {loading ? (
                <div className='flex items-center justify-center w-full h-full'>
                    <CircularProgress />
                </div>
            ) : (
                <TreemapChart chartData={data} handlePointClick={onEventClick}/>
            )}
        </div>
    );
}
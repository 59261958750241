import React, { useState, useEffect } from 'react';
import '../../assets/css/sign-in.css';
import favIcon from "../../assets/images/favicon.png";
import { postLoginInfo } from "../../api";
import { deleteAllCookies, deleteLocalStorage } from "../../utils";

export default function SignInPage() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(0);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleRememberMeChange = (event) => {
        setRememberMe(event.target.checked ? 1 : 0);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        postLoginInfo(email, password, rememberMe).then((res) => {
            if (res.status) {
                window.location.href = '/'
            } else {
                console.log(res)
                alert("Invalid email or password: Please try again.")
            }
        })
        // Handle sign-in logic here
    };

    useEffect(() => {
        console.log("Sign in page")
        deleteAllCookies()
        deleteLocalStorage()
    }, [])

    return <div className="sign-in-container">
        <div className="header-bar">
            <img src={favIcon} alt='filter' className='logo-small' />
        </div>
        <div className="info-body">
            <h2 className="company-title">
                Alexandria Intel <sup className="company-title-sup">TM</sup>
            </h2>
            <div className="form-container">
                <div className="form-title">Sign in</div>
                <form onSubmit={handleSubmit}>
                    <div className="form-item">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" value={email} onChange={handleEmailChange} required />
                    </div>
                    <div className="form-item">
                        <label htmlFor="password">Password</label>
                        <input type="password" id="password" value={password} onChange={handlePasswordChange} required />
                    </div>

                    <div className="form-option">
                        <div className="justify-center">
                            <input type="checkbox" className="mr-1" id="rememberMe" checked={rememberMe}
                                onChange={handleRememberMeChange} />
                            <label htmlFor="rememberMe">Remember Me</label>
                        </div>
                        <a href="/Recoverpass">Forgot Password?</a>
                    </div>
                    <button type="submit" className="sign-btn">Sign In</button>
                </form>
            </div>
        </div>
        <div className="footer-bar">
            Copyright © 2023 Alexandria Technology. All rights reserved.
        </div>
    </div>
}
import React, { useState, useEffect } from 'react';
import '../../assets/css/events-page.css';
import { getEvents } from '../../api';
import { useSelector } from 'react-redux';
import { selectFilters, dashboardSlice, selectUserPortfolios } from '../../reducers/dashboardSlice';
import { getSourcesFromEntitlements, getColorHardcoded, findPercentileRank, getUniqueValues } from '../../utils';
import TreemapChart from '../common/TreemapChart';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';


function EventChartFull() {

    const filter = useSelector(selectFilters);
    const userPortfolios = useSelector(selectUserPortfolios);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handlePointClick = (name) => {
        dispatch(dashboardSlice.actions.setFilterType('event'));
        dispatch(dashboardSlice.actions.addFilterTags(name));
    }

    const preprocessing = (data) => {
        var parent = [
            {
                id: "positive",
                name: "positive",
            },
            {
                id: "negative",
                name: "negative",
            },
            {
                id: "neutral",
                name: "neutral",
            }
        ]
        var sortedVolumeData = data.map((item) => item.volume).sort((a, b) => a - b);
        var totalValues = sortedVolumeData.length;

        data = data.map(item => {
            return {
                name: item.event,
                volume: item.volume,
                value: findPercentileRank(sortedVolumeData, item.volume, totalValues),
                sentiment: item.sentiment,
                color: getColorHardcoded(item.sentiment),
                parent: item.sentiment > 0 ? "positive" : item.sentiment < 0 ? "negative" : "neutral"
            };
        });

        // add parent and data into one array
        var chartData = parent.concat(data);
        // return data;
        return chartData;
    }

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            if (!userPortfolios) {
                return;
            }
            try {
                let u = userPortfolios.filter(p => p.id === filter.portfolioId);
                if (u.length === 0) {
                    u = userPortfolios
                }
                var tickers = getUniqueValues(u[0].symbols);
                const requestBody = {
                    'from': filter.startDate,
                    'to': filter.endDate,
                    'sources': getSourcesFromEntitlements(filter.checkedEntitlement),
                    sentiment_change: filter.mode,
                    ticker: tickers,
                    frequency: "daily"
                }
                const response = await getEvents(requestBody);
                if (response.result != null) {
                    var d = preprocessing(response.result);
                    setData(d);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [filter, userPortfolios]);

    return (
        <div className='event-chart-full-container mb-2'>
            {loading ? (
                <div className='flex items-center justify-center w-full h-full'>
                    <CircularProgress />
                </div>
            ) : (
                <TreemapChart chartData={data} handlePointClick={handlePointClick}/>
            )}
        </div>
    );
}

export default EventChartFull;